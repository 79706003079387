import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useGetCompany } from '@src/api/company'
import { usePerformanceStats } from '@src/api/goals'
import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { NavigationConfigInterface } from '@src/components/StatNavigation/types'
import { ROUTES } from '@src/constants/routes'
import { getScopeFilters } from '@src/features/Engagement/helpers'
import {
  useCanViewEngagement,
  useGetEngagementScorePreview,
} from '@src/features/Engagement/hooks'
import { FilterByInterface } from '@src/interfaces/data'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { useGetNPS } from './useGetNPS'

interface Props {
  goalFilters?: FilterByInterface[]
  roadmapFilters?: FilterByInterface[]
  eNPS?: number | null
}

interface ConfigInterface {
  overall_progress: number
  avg_done: number
  talent: number
  dashboard_count: number
  issues: number
  risk: number
  engagement: number | null
}

const getConfig = (
  isEngagementV2: boolean,
): NavigationConfigInterface<ConfigInterface>[] => [
  {
    key: 'overall_progress',
    label: 'Goals',
    to: ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL,
    isPercent: true,
  },
  {
    key: 'avg_done',
    label: 'Roadmaps',
    to: ROUTES.ORGANISATION.COMPANY.ROADMAP,
    isPercent: true,
    canView: {
      globalSettings: [GlobalSettings.RoadmapsEnabled],
    },
  },
  {
    key: 'talent',
    label: 'Talent',
    to: ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE,
    isPercent: true,
    canView: {
      permissions: [PermissionTypes.GlobalTalentViewerPermissions],
    },
  },
  {
    key: 'dashboard_count',
    label: 'Analytics',
    to: ROUTES.ORGANISATION.COMPANY.ANALYTICS,
    canView: {
      globalSettings: [GlobalSettings.CommercialProductDisabled],
    },
  },
  {
    key: 'issues',
    label: 'Issues',
    to: ROUTES.ORGANISATION.COMPANY.ISSUES,
    canView: {
      featureFlags: [FeatureFlags.Findings],
    },
  },
  {
    key: 'risk',
    label: 'Risk',
    path: ROUTES.ORGANISATION.COMPANY.RISK.ANY,
    to: ROUTES.ORGANISATION.COMPANY.RISK.SUMMARY,
    canView: {
      permissions: [PermissionTypes.RiskManagerPermissions],
      globalSettings: [GlobalSettings.CommercialProductDisabled],
    },
  },
  {
    key: 'engagement',
    label: 'Engagement',
    path: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.ANY,
    to: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.CATEGORIES,
    isPercent: !isEngagementV2,
    canView: {
      permissions: [PermissionTypes.ViewEngagementTabs],
    },
  },
]

export const CompanyNavigation = ({ goalFilters, roadmapFilters, eNPS }: Props) => {
  const api = usePerformanceStats({
    goalFilters,
    roadmapFilters,
    isDepartmentsRoadmap: true,
  })
  const { data, isLoading } = useGetCompany()

  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  const { canView: canViewEngagement } = useCanViewEngagement({ entity: data })
  const { score, isLoading: isLoadingNPS } = useGetNPS()
  const engagementScorePreviewV2 = useGetEngagementScorePreview(
    canViewEngagement,
    getScopeFilters(data, 'company'),
  )

  const engagementV1Score = eNPS === null ? null : score || null
  const engagementV2Score = engagementScorePreviewV2.scoreValue || null
  const isLoadingEngagementScore = isEngagementV2
    ? engagementScorePreviewV2.isLoading
    : isLoadingNPS

  const stats = {
    data: {
      overall_progress: api.data?.overall_progress || 0,
      avg_done: api.data?.avg_done || 0,
      dashboard_count: data?.dashboard_count || 0,
      talent: (data?.talent || 0) * 100,
      issues: data?.issues || 0,
      risk: data?.risk_incidents || 0,
      engagement: isEngagementV2 ? engagementV2Score : engagementV1Score,
    },
    isLoading: api.isLoading || isLoading || isLoadingEngagementScore,
  }

  const config = useMemo(() => getConfig(isEngagementV2), [isEngagementV2])

  return <StatNavigation config={config} api={() => stats} />
}
