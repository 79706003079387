import React from 'react'
import {
  useGetEmployeeTotalCompensationTimeSeries,
  useGetEmployeeTotalCompensationStats,
} from '@src/api/totalCompensation'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { CompensationOverTime } from './CompensationOverTime'
import { CompensationTotal } from './CompensationTotal'
import { ErrorWidget } from './ErrorWidget'
import { getHeight } from './helpers'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationWidget = ({ compensationParams, id }: Props) => {
  const isCommercial = useIsCommercial()
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, error, isError, isLoading } = useGetEmployeeTotalCompensationTimeSeries(
    id,
    filters,
    !!isCommercial,
  )
  const { data: totalCompensationStats, isLoading: isTotalCompensationStatsLoading } =
    useGetEmployeeTotalCompensationStats(id, filters, !!isCommercial)

  if (isError) {
    const errorsData = error?.response?.data
    const contractErrorsData = errorsData?.contract
    const errorMessage = contractErrorsData || getStringMessageFromError(error)

    return (
      <ErrorWidget id={id} isContractError={!!contractErrorsData} title={errorMessage} />
    )
  }

  const { height, minHeight } = getHeight(totalCompensationStats)

  return (
    <TwoColumnsLayout
      left={
        <CompensationTotal
          data={totalCompensationStats}
          isLoading={isTotalCompensationStatsLoading}
          compensationParams={compensationParams}
          id={id}
          height={height}
          minHeight={minHeight}
        />
      }
      right={
        <CompensationOverTime
          data={data}
          isLoading={isLoading}
          height={height}
          minHeight={minHeight}
        />
      }
      rightFlex={3}
    />
  )
}
