import React from 'react'
import { Text } from '@revolut/ui-kit'

import { useGetSurveyAnalytics } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { normalizedScoreToColor } from '@src/apps/People/Engagement/helpers'
import { selectorKeys } from '@src/constants/api'
import { FieldOptions, IdAndName } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { EngagementSurveyAnalyticsInterface } from '@src/interfaces/engagement'
import { PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'

export const useCanViewEngagement = <
  T extends { id: number; field_options: FieldOptions },
>({
  entity,
  checkLocalPermission,
}: {
  entity: T | undefined
  checkLocalPermission?: boolean
}) => {
  const { settings, isLoading } = useGlobalSettings()
  const hasGlobalPermission = useHasGlobalPermission([PermissionTypes.ViewEngagementTabs])
  const hasLocalPermission = checkLocalPermission
    ? entity?.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs)
    : true

  return {
    canView: Boolean(
      entity?.id &&
        settings.engagement_enabled &&
        hasGlobalPermission &&
        hasLocalPermission,
    ),
    isLoading,
  }
}

interface ScorePreviewProps {
  analyticsData: EngagementSurveyAnalyticsInterface | undefined
  isLoading: boolean
}
const ScorePreview = ({ analyticsData, isLoading }: ScorePreviewProps) => {
  if (isLoading) {
    return null
  }
  return (
    <Text color={normalizedScoreToColor(analyticsData?.average_score)}>
      {analyticsData?.average_score ?? 'n/a'}
    </Text>
  )
}

export const useGetEngagementScorePreview = (
  enabled: boolean,
  scopeFilters: FilterByInterface[],
) => {
  const { data: surveyOptions, isLoading: isLoadingSurveyOptions } =
    useGetSelectors<IdAndName>(selectorKeys.engagement_survey_titles_sorted, undefined, {
      enabled,
    })
  const latestSurvey = surveyOptions?.[0]
  const { data: surveyAnalyticsData, isLoading: isLoadingSurveyAnalyticsData } =
    useGetSurveyAnalytics(latestSurvey?.id, scopeFilters, enabled)
  const isLoading = isLoadingSurveyOptions || isLoadingSurveyAnalyticsData

  return {
    isLoading,
    scoreValue: surveyAnalyticsData?.average_score,
    scorePreview: (
      <ScorePreview analyticsData={surveyAnalyticsData} isLoading={isLoading} />
    ),
  }
}
