import React from 'react'
import { useGetEmployeeEquityTimeSeries } from '@src/api/totalCompensation'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EquityOverTime } from './EquityOverTime'
import { EquityTotal } from './EquityTotal'
import { ErrorWidget } from './ErrorWidget'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const EquityWidget = ({ compensationParams, id }: Props) => {
  const isCommercial = useIsCommercial()
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, error, isError, isLoading } = useGetEmployeeEquityTimeSeries(
    id,
    filters,
    !!isCommercial,
  )

  if (isError) {
    const errorsData = error?.response?.data
    const contractErrorsData = errorsData?.contract
    const errorMessage = contractErrorsData || getStringMessageFromError(error)

    return (
      <ErrorWidget id={id} isContractError={!!contractErrorsData} title={errorMessage} />
    )
  }

  return (
    <TwoColumnsLayout
      left={
        <EquityTotal
          compensationParams={compensationParams}
          id={id}
          isCommercial={!!isCommercial}
        />
      }
      right={<EquityOverTime data={data} isLoading={isLoading} />}
      rightFlex={3}
    />
  )
}
