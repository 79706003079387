import { RowInterface } from '@src/interfaces/data'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  employeeColumn,
  recurrenceColumn,
  relationColumn,
  statusColumn,
  timeColumn,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/constants/columns'
import { oneToOneModel } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/models/oneToOneModel'

export const getRow = (
  currentUser: EmployeeInterface,
): RowInterface<OneToOneMeeting> => ({
  cells: [
    { ...employeeColumn, width: 200 },
    { ...relationColumn, width: 200 },
    { ...timeColumn, width: 200 },
    { ...recurrenceColumn, width: 100 },
    { ...statusColumn, width: 100 },
  ],
  disabled: (meeting: OneToOneMeeting) => {
    if (
      oneToOneModel.isScheduled(meeting) &&
      oneToOneModel.isMeetingParticipant(currentUser, meeting)
    ) {
      return false
    }

    return !oneToOneModel.isMeetingManagedBy(currentUser, meeting)
  },
})
