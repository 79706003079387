import { Color, IconName, Token } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types'
import { UpdateTypes } from '@src/interfaces/kpis'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'

export const updateTypeOptionMap: Record<
  UpdateTypes,
  {
    name: string
    description: string
    image?: ImageProp
    icon?: IconName
    iconBackground?: Color
    requiredIntegrationPermission?: PermissionTypes
    featureFlag?: FeatureFlags
    placeholderDescription?: string
  }
> = {
  // Ordered in a way they should be displayed
  [UpdateTypes.manual]: {
    name: 'Manual',
    description: 'Manually update the current value',
    icon: 'Pencil',
  },
  [UpdateTypes.sql]: {
    name: 'SQL',
    description: 'Connect your goals via SQL',
    icon: 'Database',
    iconBackground: Token.color.actionLabel,
  },
  [UpdateTypes.roadmap]: {
    name: 'Jira',
    description: 'Use Jira to automatically update your progress',
    placeholderDescription: 'Provide access to you Jira account in 2 easy steps',
    icon: 'Jira|image',
    iconBackground: Token.color.actionLabel,
    requiredIntegrationPermission: PermissionTypes.ViewJiraIntegration,
  },
  [UpdateTypes.clickup]: {
    name: 'ClickUp',
    description: 'Connect your ClickUp tasks to track progress',
    placeholderDescription: 'Provide access to you ClickUp account in 1 easy steps',
    requiredIntegrationPermission: PermissionTypes.ViewClickupIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
      '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
    },
  },
  [UpdateTypes.looker]: {
    name: 'Looker',
    description: 'Use a Look to automatically update the current value',
    placeholderDescription: 'Provide access to you Looker account in 4 easy steps',
    image: {
      default: 'https://assets.revolut.com/assets/apps/Looker.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Looker@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Looker@3x.webp',
    },
    requiredIntegrationPermission: PermissionTypes.ViewLookerIntegration,
  },
  [UpdateTypes.tableau]: {
    name: 'Tableau',
    description: 'Connect your Tableau tasks to track progress',
    placeholderDescription: 'Provide access to you Tableau account in 4 easy steps',
    requiredIntegrationPermission: PermissionTypes.ViewTableauIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/Tableau.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Tableau@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Tableau@3x.webp',
    },
  },
  [UpdateTypes.salesforce]: {
    name: 'Salesforce',
    description: 'Update your goals automatically based on data from reports',
    placeholderDescription: 'Provide access to you Salesforce account in 3 easy steps',
    requiredIntegrationPermission: PermissionTypes.ViewSalesforceIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/Salesforce.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Salesforce@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Salesforce@3x.webp',
    },
  },
  [UpdateTypes.linear]: {
    featureFlag: 'not-available-yet' as FeatureFlags,
    name: 'Linear',
    description: 'Connect your Linear tasks to track progress',
    placeholderDescription: 'Provide access to you Linear.app account in 3 easy steps',
    requiredIntegrationPermission: PermissionTypes.ViewLinearIntegration,
    image: {
      default: 'https://assets.revolut.com/assets/apps/Linear.webp',
      '2x': 'https://assets.revolut.com/assets/apps/Linear@2x.webp',
      '3x': 'https://assets.revolut.com/assets/apps/Linear@3x.webp',
    },
  },
}
